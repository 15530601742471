import React, {
  useState
} from 'react';
import {
  navigateTo
} from 'gatsby-link';
import Layout from '../components/layout.js';

export default () => {
  const [formState, setFormState] = useState({
    comment: '',
    email: ''
  });

  const handleInput = (e) => {
    e.preventDefault();
    let newState = {
      ...formState
    };
    newState[e.target.id] = e.target.value;
    setFormState({
      ...newState
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...formState
        })
      })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  return (
    <Layout name="contact">
      <h2>I'd Love to hear from you!</h2>
      <form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <div className="form-group">
          <label for="comment">Your Comment</label>
          <textarea name="comment" id="comment" value={formState.comment} onInput={handleInput}></textarea>
        </div>
        <div className="form-group">
          <label for="email">Your Email</label>
          <input type="email" name="email" id="email" value={formState.email} onInput={handleInput} />
        </div>
        <input type="submit" value="send it to me!"/>
      </form>
    </Layout>
  );
};

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
